import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'cs-map-legend-tree',
    templateUrl: './map-legend-tree.component.html',
    styleUrls: [ './map-legend-tree.component.scss' ]
})
export class MapLegendTreeComponent implements OnInit {

    constructor(
    ) { }

    ngOnInit(): void {
    }

}
