import { NgModule } from '@angular/core';
import { AttachDialogComponent } from './dialog/attach-dialog.component';
import { SharedModule } from '../../../shared/shared.module';
import { CsUiModule } from '../../../../core/ui/csUi.module';
import { ATTACH_COMMAND } from './attach.token';
import { attachFactory } from './attachFactory';

@NgModule({
    declarations: [AttachDialogComponent],
    imports: [SharedModule, CsUiModule],
    providers: [{ provide: ATTACH_COMMAND, useFactory: attachFactory }],
})
export class AttachModule {}
